import React from 'react';
import styled from 'styled-components';
import { Button } from 'components/button';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import MEDIA from 'styles/media';

const STextMonthlyPromo = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 100px;

  ${MEDIA.max1024`
    padding: 0 20px;
  `};
`;

const SHeader = styled.h2`
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: bold;
`;

const SSubHeader = styled.p`
  font-size: 18px;
  margin-bottom: 10px;
`;

const SText = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
`;

const SButton = styled(Button)`
  margin-top: 1rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

const SList = styled.ul`
  margin: 0;
  list-style-type: circle;
  text-align: left;
  list-style-position: outside;
`;
const SListItem = styled.li``;

export const TextMonthlyPromo = () => {
  return (
    <STextMonthlyPromo>
      <SHeader>
        SPECJALNE ZIMOWE PROMOCJE!
      </SHeader>
      <SSubHeader>
        Zimowa styczniowa promocja na USŁUGĘ WYPEŁNIANIA BRUZD I ZMARSZCZEK czeka na Ciebie!
      </SSubHeader>
      <SButton
        text="Zarezerwuj wizytę"
        target="_blank"
        href={EXTERNAL_LINKS.BOOKSY_BOOKING_APP}
        on
      />
      <SText>
        Jeśli zdecydujesz się na usługę wypełnienia bruzd / zmarszczek, tj. bruzdy nosowo-wargowych, linii marionetek, zmarszczek palacza, zapłacisz:
      </SText>
      <SList>
        <SListItem><strong>za 1 ml zapłacisz 800 zł</strong> zamiast 900 zł</SListItem>
        <SListItem><strong>za 2 ml zapłacisz 1 050 zł</strong> zamiast 1 200 zł</SListItem>
      </SList>
      <SText>
        Sprawdź dostępne terminy i umów się na bezpłatną konsultację kwalifikującą do zabiegu.
      </SText>
      <SButton
        text="Zarezerwuj wizytę"
        target="_blank"
        href={EXTERNAL_LINKS.BOOKSY_BOOKING_APP}
        on
      />
      <SText>
        Po 14 dniach odbędzie się  <strong>bezpłatna kontrola</strong>.
      </SText>
      <SText>
        Wybieraj bezpieczne i sprawdzone rozwiązania. <strong>Lekarze</strong> w klinice IDEALE pracują tylko na <strong>legalnych
        medycznych produktach</strong>. ZAPISZ SIĘ na wizytę kwalifikującą do zabiegu już dziś. Zapraszamy!
      </SText>
    </STextMonthlyPromo>
  );
};
